import React, {useState} from 'react';
import "./index.css"
import logo from "./assets/group372.svg"
import {Formik, Field, Form, ErrorMessage} from "formik";
import * as yup from "yup";
import {Alert} from "react-bootstrap";
import BKServiceComponent from "../../contexts/BackendServiceComponent";
import {Navigate} from "react-router-dom";
import Spinner from "../Spinner";

export function Authorization() {

    const [openAlert, setOpenAlert] = useState('');
    const [spinner, setSpinner] = useState(false);

    const bkService = React.useContext(BKServiceComponent);

    //state validation by YUP
    const validationSchema = yup.object({
        login: yup.string()
            .required('Required field'),
        password: yup.string()
            .required('Required field'),
    });

    const storageToken = localStorage.getItem("licenseToken");
    if (storageToken && storageToken !== 'null') {
        return <Navigate to={"/"}/>
    }


    return (
        <div className="wrapper fadeInDown">
            {spinner && <Spinner/>}
            <div id="formContent">

                <div className="fadeIn first">
                    <div className={"blueRec"}>
                        <img src={logo} className={"logo"} alt="logo"/>
                    </div>
                </div>
                <Formik
                    validationSchema={validationSchema}
                    initialValues={{login: "", password: "", submit:""}}
                    onSubmit={async ({login,password}, { setErrors }) => {
                        setSpinner(true);
                        try {
                            const res = await bkService.userLogin(login,password);
                            if (res?.token) {
                                localStorage.setItem("licenseToken", res.token);
                                //window.location.reload();
                            }
                        } catch (e) {
                            console.log(e.message);
                            setErrors({submit:  e?.message})
                        }
                        setSpinner(false);
                    }}
                >
                    <Form className="form">
                        <Field
                            type="text"
                            className="fadeIn second"
                            name="login"
                            placeholder="login"/>
                        <ErrorMessage  component="div"  name="login" />
                        <Field
                            type="password"
                            className="fadeIn third"
                            name="password"
                            placeholder="password"/>
                        <ErrorMessage  component="div"  name="password" />

                        <input type="submit" className="fadeIn fourth" value={"log in"} title={"log in"}/>
                        <ErrorMessage  component="div"  name="submit" />
                    </Form>
                </Formik>
                {!!openAlert &&
                    <Alert
                        onClose={() => setOpenAlert('')}
                        dismissible
                        className={`m-0`}
                        variant="danger">
                        <p className={`m-0`}>{openAlert}</p>
                    </Alert>
                }

                <div id="formFooter">
                    <a className="underlineHover" href="/">Forgot Password?</a>
                </div>
            </div>
        </div>
    )
}