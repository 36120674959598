import React from 'react';
import {FormikProvider} from "formik";
import {Container} from "react-bootstrap";

export function RedirectSection({formik, user, getRow, section}) {
    return (
        <FormikProvider value={formik}>
            <Container fluid>
                {getRow(section, "netTimeout", "text")}
                {user.system.license.enabledRedirects.map(v => getRow("redirect", v, "checkbox"))}
            </Container>
        </FormikProvider>
    );
}