import React from 'react';
import {Collapse, Container} from "react-bootstrap";
import {FormikProvider} from "formik";

export function Database({formik, section, componentData, getRow}) {
    return (
        <FormikProvider value={formik}>
            <Container fluid>
                {getRow(section, "dbSimple", "checkbox")}
                {getRow(section, "DBConnectionTimeout", "text")}
                {getRow(section, "permanentConnections", "checkbox")}
                {getRow(section, "maxPoolSize", "text")}
                {getRow(section, "SqlMaxLimit", "text")}
                {getRow(section, "DBType", "select",componentData[section][0][2])}
                {getRow(section, "cacheDir", "text")}
                {getRow(section, "DatabaseName", "text")}
                {getRow(section, "PostQuery", "text")}
                <Collapse in={!(!!formik.values["DBSettings_dbSimple"] || [1,4].indexOf(parseInt(formik.values[section+"_DBType"])) > -1)}>
                    <Container fluid>
                        {componentData[section].map(v=> ["permanentConnections","SqlMaxLimit","dbSimple","DBType","cacheDir","DatabaseName","DBConnectionTimeout","maxPoolSize", "PostQuery"].indexOf(v[0]) === -1 ? getRow(section,v[0],v[1],v[2]) : null)}
                    </Container>
                </Collapse>
            </Container>
        </FormikProvider>
    );
}